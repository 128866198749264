@import "./variables-inverse.scss";
@import "./components.scss";

//
// Custom form overrides
//

.custom-select {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAUCAMAAACzvE1FAAAADFBMVEX////////////////1pQ5zAAAAA3RSTlMAf4C/aSLHAAAAPElEQVR42q3NMQ4AIAgEQTn//2cLdRKppSGzBYwzVXvznNWs8C58CiussPJj8h6NwgorrKRdTvuV9v16Afn0AYFOB7aYAAAAAElFTkSuQmCC);

  &:focus {
    color: $body-bg;
    background-color: #fff;
    background-image: url( data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAUCAMAAACzvE1FAAAADFBMVEUzMzMzMzMzMzMzMzMKAG/3AAAAA3RSTlMAf4C/aSLHAAAAPElEQVR42q3NMQ4AIAgEQTn//2cLdRKppSGzBYwzVXvznNWs8C58CiussPJj8h6NwgorrKRdTvuV9v16Afn0AYFOB7aYAAAAAElFTkSuQmCC);
    box-shadow: none;
  }
}

.custom-checkbox input:checked ~ .custom-control-indicator {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSItMzAxIDM5MyA4IDgiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgLTMwMSAzOTMgOCA4IiB4bWw6c3BhY2U9InByZXNlcnZlIj48cGF0aCBmaWxsPSIjMjUyODMwIiBkPSJNLTI5NC42LDM5NGwtMC43LDAuN2wtMi44LDIuOGwtMC44LTAuOGwtMC43LTAuN2wtMS40LDEuNGwwLjcsMC43bDEuNSwxLjVsMC43LDAuN2wwLjctMC43bDMuNS0zLjVsMC43LTAuN0MtMjkzLjEsMzk1LjQtMjk0LjYsMzk0LTI5NC42LDM5NHoiLz48L3N2Zz4=);
}
.custom-radio input:checked ~ .custom-control-indicator {
  background-clip: border-box;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSItMzAxIDM5MyA4IDgiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgLTMwMSAzOTMgOCA4IiB4bWw6c3BhY2U9InByZXNlcnZlIj48cGF0aCBmaWxsPSIjMjUyODMwIiBkPSJNLTI5NywzOTRjLTEuNywwLTMsMS4zLTMsM3MxLjMsMywzLDNzMy0xLjMsMy0zUy0yOTUuMywzOTQtMjk3LDM5NHoiLz48L3N2Zz4=);
}



// we do a highlevel override here for colors because we opt not to inverse
// these components backgrounds :)
.popover,
.popover-title,
.popover h1,
.popover h2,
.popover h3,
.popover h4,
.popover h5,
.popover h6,
.popover .h1,
.popover .h2,
.popover .h3,
.popover .h4,
.popover .h5,
.popover .h6,
.popover small,
.popover .small,
.modal,
.modal-title,
.modal h1,
.modal h2,
.modal h3,
.modal h4,
.modal h5,
.modal h6,
.modal .h1,
.modal .h2,
.modal .h3,
.modal .h4,
.modal .h5,
.modal .h6,
.modal small,
.modal .small {
  color: $gray-800;
}
.popover .form-control,
.modal .form-control {
  color: $gray-800;
  border-color: $modal-content-border-color;
  background: $modal-content-bg;
  @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
  @include form-control-focus();
}

/* simple contextual color overrides */
.nav > li > a {
  color: #cfd2da;
}
.nav > li > a:hover,
.nav > li > a:focus {
  color: #fff;
}

.form-control {
  box-shadow: none;
}

// input group magic
.has-success,
.has-warning,
.has-error {
  .form-control,
  .form-control:focus {
    border-color: $input-border;
    box-shadow: none;
  }
  .input-group-addon {
    color: #fff;
    background-color: lighten($body-bg, 18%);
    border-color: transparent;
  }
}
